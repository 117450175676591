<template>
  <div class="edit-project-unit">
    <div class="card">
      <fd-form class="modal-content-wrapper" @submit.prevent="submitForm">
        <modal-body class="p-2">
          <div v-if="isLoaded" class="container fluid">
            <h3 class="mt-2 mb-4">Edit Unit</h3>

            <fd-form-section title="Unit Detail">
              <fd-input
                v-model="unit.street"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Street"
                name="unitStreet"
                type="text"
              >
              </fd-input>
              <fd-select
                v-model="unit.status"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Status"
                :options="statusOptions"
                :validators="[validator.required]"
              >
              </fd-select>
              <fd-select
                v-model="unit.projectPhaseId"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Phase"
                :options="phaseOptions"
                :validators="[validator.required]"
                :optionValue="(item) => item.id"
                :optionLabel="(item) => item.name"
              >
              </fd-select>
              <fd-select
                v-model="unit.orientation"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Orientation"
                :options="orientationOptions"
              >
              </fd-select>
              <fd-select
                v-model="unit.furnishingType"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Furnishing Type"
                selectText="Select a furnishing type"
                :options="furnishingTypeOptions"
                :validators="[validator.required]"
              >
              </fd-select>
              <fd-select
                v-model="unit.lotTypeId"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Lot Type"
                name="lotTypeId"
                placeholder="Choose a lot type"
                :options="lotTypeOptions"
              >
              </fd-select>
              <fd-multi-select
                v-model="unit.tenureType"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Tenure Type"
                name="tenureType"
                selectText="Select tenure type"
                :options="tenureTypeOptions"
                :validators="[validator.required]"
              ></fd-multi-select>
            </fd-form-section>

            <fd-form-section title="Pricing">
              <!-- Booking Fee -->
              <div class="col-12 mb-2">
                <fd-input
                  v-model="unit.bookingFee"
                  class="col-12 sm-col-6 md-col-4 px-1 mb-1"
                  label="Booking Fee"
                  name="bookingFee"
                  type="text"
                  :validators="[validator.required, validator.price]"
                  :disabled="!unit.isEditBookingFee"
                >
                </fd-input>
                <div
                  v-if="!unit.isEditBookingFee"
                  class="col-12 sm-col-6 md-col-4 px-1"
                >
                  <p class="edit-size-hint">
                    Cannot edit the booking fee due to the unit type setting
                  </p>
                </div>
              </div>
              <!-- Sale Price -->
              <fd-input
                v-model="unit.salePrice"
                class="col-12 sm-col-6 md-col-4 px-1 mb-2"
                label="Sale Price (RM)"
                name="salePrice"
                type="number"
                :validators="[validator.price]"
                @input="updatePrice"
              >
              </fd-input>
              <!-- Unit Price Type -->
              <fd-select
                v-model="pricePerUnitOrigin"
                class="col-12 sm-col-6 md-col-4 px-1 mb-2"
                label="Unit Price Calculate With"
                name="unitPriceType"
                :options="unitPriceCalculateOptions"
                :optionValue="
                  (option) => {
                    return option;
                  }
                "
                :optionLabel="
                  (option) => {
                    return option;
                  }
                "
                :validators="[validator.required]"
                @change="updatePrice"
              >
              </fd-select>
              <div class="col-12 md-col-6">
                <div class="sales-price-per-unit mx-1">
                  <label class="label">Sales Price per Unit</label>
                  <p v-if="!unit.areaUnitType">
                    Choose an area unit type first
                  </p>
                  <p v-else>
                    RM{{ unit.salePricePerUnit || "0.00" }}
                    {{ unit.areaUnitType }}
                  </p>
                </div>
              </div>
            </fd-form-section>

            <fd-form-section title="Size Detail">
              <div v-if="!unit.isEdit" class="col-12 px-1 mb-2">
                <p class="edit-size-hint">
                  Cannot edit this part due to the floor plan setting
                </p>
              </div>

              <!-- Land Width -->
              <fd-input
                v-model="unit.landWidth"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Land Width"
                name="landWidth"
                type="number"
                :disabled="!unit.isEdit"
                @input="landChanged"
              >
              </fd-input>
              <!-- Land Depth -->
              <fd-input
                v-model="unit.landDepth"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Land Depth"
                name="landDepth"
                type="number"
                :disabled="!unit.isEdit"
                @input="landChanged"
              >
              </fd-input>
              <!-- Land Area -->
              <fd-input
                v-model="unit.landArea"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Land Area"
                name="landArea"
                type="number"
                :disabled="!unit.isEdit"
                @input="onLandAreaInput"
                :validators="[validator.required]"
              >
              </fd-input>
              <!-- Area Unit Type -->
              <fd-input
                v-model="unit.areaUnitType"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Area Unit Type"
                name="areaUnitType"
                :options="areaUnitTypeOptions"
                disabled
              >
              </fd-input>
              <!-- Built Up Width -->
              <fd-input
                v-model="unit.builtUpWidth"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Built Up Width"
                name="builtUpWidth"
                type="number"
                :disabled="!unit.isEdit"
                @input="builtUpChanged"
              >
              </fd-input>
              <!-- Built Up Depth -->
              <fd-input
                v-model="unit.builtUpDepth"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Built Up Depth"
                name="builtUpDepth"
                type="number"
                :disabled="!unit.isEdit"
                @input="builtUpChanged"
              >
              </fd-input>
              <!-- Built Up Area -->
              <fd-input
                v-model="unit.builtUpArea"
                class="col-12 sm-col-6 md-col-3 px-1 mb-2"
                label="Built Up Area"
                name="builtUpArea"
                type="number"
                :disabled="!unit.isEdit"
                @input="onBuiltUpAreaInput"
                :validators="[validator.required]"
              >
              </fd-input>
            </fd-form-section>

            <fd-form-section title="Images">
              <div class="col-12 px-1 mb-2">
                <image-uploader
                  v-model="unit.gallery"
                  label="Gallery Photos"
                  multiple
                  watermark
                  @error="
                    (error, imageName) => {
                      $reportError(error, 'Upload gallery (Edit Unit)');
                    }
                  "
                >
                </image-uploader>
              </div>
            </fd-form-section>

            <fd-form-section title="VR">
              <!-- VR Link -->
              <fd-input
                v-model="unit.vrLink"
                class="col-12 sm-col-6 px-1 mb-2"
                label="VR Link"
                name="vrLink"
                type="text"
              ></fd-input>
            </fd-form-section>

            <fd-form-section title="Description">
              <basic-tip-tap
                class="col-12 mb-2"
                v-model="unit.description"
              ></basic-tip-tap>
            </fd-form-section>

            <!-- Title Deed -->
            <fd-form-section title="Title">
              <file-uploader
                v-model="unit.titleDeed"
                class="col-12 px-1 mb-2"
                label="Title Upload"
                :maxSize="5"
                multiple
                :maxFile="10"
                :allowed="['pdf', 'jpg', 'png', 'jpeg']"
                accept=".pdf, image/jpeg, image/png"
                @isLoading="
                  (isLoading) => {
                    this.isFormLoading = isLoading;
                  }
                "
              ></file-uploader>
            </fd-form-section>
            <!-- Additional Document -->
            <fd-form-section title="Additional Document">
              <file-uploader
                v-model="unit.additionalDocument"
                class="col-12 px-1 mb-2"
                label="Additional Documents"
                :maxSize="5"
                multiple
                :maxFile="10"
                :allowed="['pdf', 'jpg', 'png', 'jpeg']"
                accept=".pdf, image/jpeg, image/png"
                @isLoading="
                  (isLoading) => {
                    this.isFormLoading = isLoading;
                  }
                "
              ></file-uploader>
            </fd-form-section>
          </div>
        </modal-body>

        <!-- Action Footer -->
        <modal-footer class="p-2">
          <div class="col-12 text-right">
            <fd-button
              type="button"
              class="medium mr-1"
              @click="$emit('cancel')"
            >
              Cancel
            </fd-button>
            <fd-button
              type="submit"
              class="medium main"
              loadingEnabled
              :isLoading="isFormLoading"
              :disabled="isFormLoading"
              >Update
            </fd-button>
          </div>
        </modal-footer>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  price
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import {
  projectUnitStatus,
  areaUnitType,
  orientation,
  furnishingType,
  tenureType
} from "@/enums";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectPhaseAPI from "@/modules/Project/api/projectPhase";
import ProjectUnitAPI from "@/modules/Project/api/projectUnit";
import ProjectUnitModel from "@/modules/Project/models/ProjectUnitModel";

export default {
  components: {
    BasicTipTap: () => import("@/components/GlobalComponents/BasicTipTap"),
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader"),
    FileUploader: () => import("@/components/GlobalComponents/FileUploader"),
    FdMultiSelect: () =>
      import("@/components/GlobalComponents/FormComponents/FdMultiSelect")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    projectId: {
      type: [Number, String]
    },
    projectUnitTypeId: {
      type: [Number, String]
    },
    unitId: {
      type: [Number, String]
    }
  },
  data: function () {
    return {
      statusOptions: this.$mapJsonToArray(projectUnitStatus, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      orientationOptions: this.$mapJsonToArray(orientation, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      areaUnitTypeOptions: this.$mapJsonToArray(areaUnitType, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      furnishingTypeOptions: this.$mapJsonToArray(furnishingType, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      tenureTypeOptions: this.$mapJsonToArray(tenureType, (e) => {
        return e;
      }),

      unitPriceCalculateOptions: ["Built Up", "Land"],
      pricePerUnitOrigin: "Built Up",

      lotTypeOptions: [],
      phaseOptions: [],

      unit: {},
      isLoaded: false,
      isFormLoading: false,

      validator: {
        required: required,
        price: price
      },

      projectUnitAPI: new ProjectUnitAPI(this.type),
      phaseAPI: new ProjectPhaseAPI(this.type)
    };
  },
  computed: {
    areaValue: function () {
      let val = 0;
      if (this.pricePerUnitOrigin == "Built Up") {
        val = this.unit.builtUpArea;
      } else if (this.pricePerUnitOrigin == "Land") {
        val = this.unit.landArea;
      }
      return val;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      await this.getUnit();

      // Get lot type options
      let lotTypes = await this.getLotTypes();
      this.lotTypeOptions = this._.cloneDeep(lotTypes);
      await this.getPhaseOptions();

      this.isLoaded = true;
      this.$store.commit("setIsLoading", false);
    },
    // =========================== LAND CALCULATIONS ===========================
    updatePrice() {
      this.unit = {
        ...this.unit,
        salePricePerUnit: this.updateUnitPrice(this.unit.salePrice)
      };
    },
    landChanged() {
      this.unit.landArea = this.calculateArea(
        this.unit.landWidth,
        this.unit.landDepth
      );
    },
    builtUpChanged() {
      this.unit.builtUpArea = this.calculateArea(
        this.unit.builtUpWidth,
        this.unit.builtUpDepth
      );
    },
    onLandAreaInput() {
      if (this.pricePerUnitOrigin == "Land") {
        this.updatePrice();
      }
    },
    onBuiltUpAreaInput() {
      if (this.pricePerUnitOrigin == "Built Up") {
        this.updatePrice();
      }
    },
    calculateArea(width = 0, depth = 0) {
      try {
        let area = parseFloat(parseFloat(width) * parseFloat(depth)).toFixed(2);

        return area;
      } catch (error) {
        return 0;
      }
    },
    calculateUnitPrice(price, unit) {
      try {
        let unitPrice = (
          Math.round((parseFloat(price) / parseFloat(unit)) * 100) / 100
        ).toFixed(2);

        return unitPrice;
      } catch (error) {
        return 0;
      }
    },
    updateUnitPrice(salePrice = 0) {
      if (salePrice == 0 || this.areaValue == 0) {
        return "0.00";
      }

      return this.calculateUnitPrice(salePrice, this.areaValue);
    },
    // ============================== API RELATED ==============================
    async getPhaseOptions() {
      try {
        let data = await this.phaseAPI.getProjectPhases({
          queries: {
            "project:id": this.projectId,
            "projectUnitTypes:id": this.projectUnitTypeId
          },
          perPage: 20
        });
        this.phaseOptions = this._.cloneDeep(data.data);
      } catch (error) {
        this._vm.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get phase options. Please try again later."
        });
        throw error;
      }
    },
    async getUnit() {
      try {
        let data = await this.projectUnitAPI.getUnit(this.unitId);
        this.unit = ProjectUnitModel.getToEditResponse(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        this.$reportError(error, "Get Unit to Edit");
        throw error;
      }
    },
    async getLotTypes() {
      try {
        let data = await this.$store.dispatch("manageProperty/getLotTypes", {
          queries: {
            "propertyType:id": this.unit.propertyTypeId
          }
        });
        return data;
      } catch (error) {
        throw error;
      }
    },

    submitForm() {
      this.$emit("submit", this.unitId, ProjectUnitModel.putPayload(this.unit));
    }
  }
};
</script>

<style lang="scss">
.edit-project-unit {
  @extend %formDesign;

  .sales-price-per-unit {
    border: solid 1px #00000025;
    padding: 15px 15px;
    border-radius: 5px;
    .label {
      font-weight: bold;
    }
  }

  .edit-size-hint {
    border: solid 1px $color-secondary;
    text-align: center;
    color: $color-secondary;
    padding: 12px 20px;
    border-radius: 5px;
  }
}
</style>
