import axios from "axios";
import { reportError } from "@/utils/error-reporting";
import API from "@/modules/Project/classes/API";

class ProjectPhaseAPI extends API {
    async getProjectPhases({ queries = {}, page = 1, perPage = 30 }) {
        try {
            const response = await axios.get(
                `${ProjectPhaseAPI.apiURL}/project-phases`,
                {
                    params: { ...queries, page: page, limit: perPage }
                }
            );

            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectPhaseAPI.panelName}] Get Project Phase`
            );
            throw error;
        }
    }
    async getProjectPhase(id) {
        try {
            let include = "developerBankAccounts";
            const response = await axios.get(
                `${ProjectPhaseAPI.apiURL}/project-phases/${id}?include=${include}`
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectPhaseAPI.panelName}] Get Project Phase [ID: ${id}]`
            );
            throw error;
        }
    }

    async createProjectPhase(payload) {
        try {
            const response = await axios.post(
                `${ProjectPhaseAPI.apiURL}/project-phases`,
                payload
            );
            return response.data;
        } catch (error) {
            reportError(error, `[${ProjectPhaseAPI.panelName}] Create Phase`);
            throw error;
        }
    }

    async updateProjectPhase(id, payload) {
        try {
            const response = await axios.put(
                `${ProjectPhaseAPI.apiURL}/project-phases/${id}`,
                payload
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectPhaseAPI.panelName}] Edit Phase [ID: ${id}]`
            );
            throw error;
        }
    }

    async deleteProjectPhase(id) {
        try {
            const response = await axios.delete(
                `${ProjectPhaseAPI.apiURL}/project-phases/${id}`
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectPhaseAPI.panelName}] Delete Phase [ID: ${id}]`
            );
            throw error;
        }
    }
}

export default ProjectPhaseAPI;
